import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
const firebaseApp = initializeApp({
  apiKey: "AIzaSyAw095oTJd1uR9_JpSE9oaQ7R-81a40TjA",
  authDomain: "tingedu-4dfb0.firebaseapp.com",
  projectId: "tingedu-4dfb0",
  storageBucket: "tingedu-4dfb0.appspot.com",
  messagingSenderId: "976984431305",
  appId: "1:976984431305:web:c6d228226e3f5dc3770b18",
  measurementId: "G-PK7P680V5Q"
});

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.

const messaging = getMessaging(firebaseApp);
export default messaging;

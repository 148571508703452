// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {};

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {};

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: "TINGEDU", // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: process.env.VUE_APP_PROVIDER === 'ocb' ? require("@/assets/images/logo/logo-ocb.svg") : require("@/assets/images/logo/tingedu-logo-horizontal.png"), // Will update logo in navigation menu (Branding)
    appLogoImageWhite: require("@/assets/images/logo/tingedu-logo-white.png"), // Will update logo in navigation menu (Branding)
    appTitle: process.env.VUE_APP_PROVIDER === 'ocb' ? "GIẢI PHÁP THU VÀ QUẢN LÝ HỌC PHÍ TỰ ĐỘNG - OCB SCHOOL" : "HỆ THỐNG QUẢN LÝ THU HỌC PHÍ TINGEDU",
    copyright: process.env.VUE_APP_PROVIDER === 'ocb' ? "OCB School powered by OCB x Tingee" : "HENO,.JSC",
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "zoom-fade", // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,

    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "sticky", // static , sticky , floating, hidden
      backgroundColor: "primary", // BS color options [primary, success, etc]
    },
    footer: {
      type: "sticky", // static, sticky, hidden
    },
    customizer: false,
    gallery: false,
    enableScrollToTop: true,
  },
};
export const $brandConfig = {
  // eslint-disable-next-line global-require
  loginBgImage: require("@/assets/images/pages/tingedu_auth_bg.png"),
  loginBgColorClass: 'bg-white',
  loginWelcomeText: 'Đăng nhập! 👋',
  loginDescriptionText: 'Phần mềm Quản lý thu phí tự động dành riêng cho lĩnh vực Giáo dục & Đào tạo',
};

// eslint-disable-next-line import/no-cycle
import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error),
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response.data,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken()
              .then(r => {
                this.isAlreadyFetchingAccessToken = false;

                // Update accessToken in localStorage
                this.setToken(r.data.access_token);
                this.setRefreshToken(r.data.refresh_token);

                this.onAccessTokenFetched(r.data.access_token);
                return true;
              })
              .catch(() => {
                // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
                localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);

                // Remove userData from localStorage
                localStorage.removeItem('userData');
                this.onAccessTokenFetched(null);
                window.location.href = '/login';
                return false;
              });
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      },
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    });
  }

  firebaseToken(firebaseToken) {
    const savedClientToken = localStorage.getItem('clientToken');
    if (savedClientToken !== firebaseToken) {
      localStorage.setItem('clientToken', firebaseToken);
      return this.axiosIns.post(this.jwtConfig.firebaseTokenEndpoint, {
        firebaseToken,
      });
    }
    return firebaseToken;
  }

  getUsersByAdmin(params) {
    return this.axiosIns.get(this.jwtConfig.userListByAdminEndpoint, {
      params,
    });
  }

  getUserDetailByAdmin(userId) {
    return this.axiosIns.get(`${this.jwtConfig.userDetailByAdminEndpoint}/${userId}`);
  }

  updateUserSubscriptionByAdmin(data) {
    return this.axiosIns.post(`${this.jwtConfig.updateSubscriptionByAdminEndpoint}`, data);
  }

  changeUserPasswordByAdmin(data) {
    return this.axiosIns.post(this.jwtConfig.changeUserPasswordByAdminEndPoint, data);
  }

  // utils
  getPricing() {
    return this.axiosIns.get(this.jwtConfig.utilsPricingEndpoint);
  }

  getCountries() {
    return this.axiosIns.get(this.jwtConfig.utilsCountriesEndpoint);
  }

  getVehicleTypes() {
    return this.axiosIns.get(this.jwtConfig.utilsVehicleTypesEndpoint);
  }

  getGenders() {
    return this.axiosIns.get(this.jwtConfig.utilsGendersEndpoint);
  }

  getUnits() {
    return this.axiosIns.get(this.jwtConfig.utilsUnitsEndpoint);
  }

  getPaymentPeriods() {
    return this.axiosIns.get(this.jwtConfig.utilsPaymentPeriodsEndpoint);
  }

  getProvince() {
    return this.axiosIns.get(this.jwtConfig.utilsProvinceEndpoint);
  }

  getAbilities() {
    return this.axiosIns.get(this.jwtConfig.utilsAbilitiesEndpoint);
  }

  getMeterTypes() {
    return this.axiosIns.get(this.jwtConfig.utilsMeterTypesEndpoint);
  }

  calculateQuantity(params) {
    return this.axiosIns.get(this.jwtConfig.utilsCalculateQuantityEndpoint, { params });
  }

  getDistricts(provinceId) {
    return this.axiosIns.get(
      this.jwtConfig.utilsDistrictEndpoint, { params: { provinceId } },
    );
  }

  getWards(districtId) {
    return this.axiosIns.get(
      this.jwtConfig.utilsWardEndpoint, { params: { districtId } },
    );
  }

  // fee
  createFee(...args) {
    return this.axiosIns.post(this.jwtConfig.feeCreateEndpoint, ...args);
  }

  updateFee(data) {
    return this.axiosIns.put(`${this.jwtConfig.feeUpdateEndpoint}/${data.id}`, data);
  }

  getFeeDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.feeDetailEndpoint}/${id}`);
  }

  getFees(params) {
    return this.axiosIns.get(this.jwtConfig.feeListEndpoint, {
      params,
    });
  }

  deleteFees(data) {
    return this.axiosIns.delete(this.jwtConfig.feeDeleteEndpoint, { data });
  }

  getFeeIdentities() {
    return this.axiosIns.get(`${this.jwtConfig.feeListEndpoint}/identities`);
  }

  // fee type

  getFeeTypes() {
    return this.axiosIns.get(this.jwtConfig.feeTypeListEndpoint);
  }

  //report
  getReportByServiceType(params) {
    return this.axiosIns.get(this.jwtConfig.reportByServiceType, { params })
  }
  getReportByService(params) {
    return this.axiosIns.get(this.jwtConfig.reportByService, { params })
  }

  getReportRevenueSummary(params) {
    return this.axiosIns.get(this.jwtConfig.reportRevenueSummary, { params })
  }

  // school
  createSchool(...args) {
    return this.axiosIns.post(this.jwtConfig.schoolCreateEndpoint, ...args);
  }

  updateSchool(objects) {
    const { id, data } = objects;
    return this.axiosIns.put(`${this.jwtConfig.schoolUpdateEndpoint}/${id}`, data);
  }

  getSchools(params) {
    return this.axiosIns.get(this.jwtConfig.schoolListEndpoint, {
      params,
    });
  }

  getSchoolDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.schoolDetailEndpoint}/${id}`);
  }

  deleteSchools(data) {
    return this.axiosIns.delete(this.jwtConfig.schoolDeleteEndpoint, { data });
  }

  // class
  createClass(...args) {
    return this.axiosIns.post(this.jwtConfig.classCreateEndpoint, ...args);
  }

  updateClass(objects) {
    const { id, data } = objects;
    return this.axiosIns.put(`${this.jwtConfig.classUpdateEndpoint}/${id}`, data);
  }

  getClasses(params) {
    return this.axiosIns.get(this.jwtConfig.classListEndpoint, {
      params,
    });
  }

  getClassDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.classDetailEndpoint}/${id}`);
  }

  deleteClasses(data) {
    return this.axiosIns.delete(this.jwtConfig.classDeleteEndpoint, { data });
  }

  // student
  createStudent(...args) {
    return this.axiosIns.post(this.jwtConfig.studentCreateEndpoint, ...args);
  }

  updateStudent(objects) {
    const { id, data } = objects;
    return this.axiosIns.put(`${this.jwtConfig.studentUpdateEndpoint}/${id}`, data);
  }

  getStudents(params) {
    return this.axiosIns.get(this.jwtConfig.studentListEndpoint, {
      params,
    });
  }

  getStudentContactZalos(params) {
    return this.axiosIns.get(this.jwtConfig.studentContactZaloListEndpoint, {
      params,
    });
  }

  importStudents(...args) {
    return this.axiosIns.post(this.jwtConfig.studentImportEndpoint, ...args);
  }

  getStudentDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.studentDeleteEndpoint}/${id}`);
  }

  deleteStudents(data) {
    return this.axiosIns.delete(this.jwtConfig.studentDeleteEndpoint, { data });
  }

  deleteStudentContactZalos(data) {
    return this.axiosIns.delete(this.jwtConfig.studentContactZaloDeleteEndpoint, { data });
  }

  // invoice
  createInvoice(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceCreateEndpoint, ...args);
  }

  createInvoicPayment(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceCreatePaymentEndpoint, ...args);
  }

  updateInvoice({ id, data }) {
    return this.axiosIns.put(`${this.jwtConfig.invoiceUpdateEndpoint}/${id}`, data);
  }

  updateInvoiceTime(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceUpdateTimeEndpoint, data);
  }

  generateMultipleInvoices(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceGenerateMultipleEndpoint, ...args);
  }

  generateImportInvoiceTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceGenerateImportTemplateEndpoint, { params });
  }

  getInvoices(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceListEndpoint, {
      params,
    });
  }

  getInvoiceHtml(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceHtmlEndpoint, {
      params,
    });
  }

  previewInvoice(invoiceId) {
    return this.axiosIns.get(`${this.jwtConfig.invoicePreviewEndpoint}/${invoiceId}`);
  }

  previewInvoiceWithUUID(params) {
    return this.axiosIns.get(this.jwtConfig.invoicePreviewWithUUIDEndpoint, { params });
  }

  getInvoicePdf(params) {
    return this.axiosIns.get(this.jwtConfig.invoicePdfEndpoint, {
      params,
    });
  }

  getViettelInvoiceDraft(invoiceId) {
    return this.axiosIns.get(this.jwtConfig.invoiceViettelDraft + `/${invoiceId}`);
  }

  searchInvoice(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceSearch, { params });
  }
  issueViettelInvoice(invoiceId) {
    return this.axiosIns.get(this.jwtConfig.invoiceViettelIssue + `/${invoiceId}`);
  }

  getInvoiceImage(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceImageEndpoint, {
      params,
    });
  }

  sendInvoiceViaZaloZNS(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendViaZaloZNSEndpoint, { invoiceIds: data });
  }

  sendInvoiceViaZaloNormal(invoiceId) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceSendViaZaloNormalEndpoint}/${invoiceId}`);
  }
  sendInvoiceViaZaloOA(invoiceId) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceSendViaZaloOAEndpoint}/${invoiceId}`);
  }
  sendInvoiceViaApp(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendViaAppEndpoint, { invoiceIds: data });
  }

  sendInvoiceViaEmail(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendViaEmailEndpoint, { invoiceIds: data });
  }

  sendInvoices(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendEndpoint, data);
  }

  exportInvoices(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceExportEndpoint, {
      params,
    });
  }

  exportInvoicesAsZip(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceExportZipEndpoint, {
      params,
    });
  }

  exportInvoicesAsZipPdf(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceExportZipPdfEndpoint, {
      params,
    });
  }

  importInvoices(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceImportEndpoint, ...args);
  }

  getInvoiceAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceAnalyticsEndpoint, {
      params,
    });
  }

  getInvoiceDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceDetailEndpoint}/${id}`);
  }

  getIncomeExpenseForInvoice(id) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceGetIncomeExpenseEndpoint}/${id}`);
  }

  deleteInvoices(data) {
    return this.axiosIns.delete(this.jwtConfig.invoiceDeleteEndpoint, { data });
  }

  approveInvoice(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceApproveEndpoint, ...args);
  }

  getRemainPrepaidOfStudent(studentId) {
    return this.axiosIns.get(this.jwtConfig.remainPrepaidByStudent, { params: { studentId } });
  }

  // role
  createRole(...args) {
    return this.axiosIns.post(this.jwtConfig.roleCreateEndpoint, ...args);
  }

  updateRole(data) {
    return this.axiosIns.put(`${this.jwtConfig.roleUpdateEndpoint}/${data.id}`, data);
  }

  getRoles(params) {
    return this.axiosIns.get(this.jwtConfig.roleListEndpoint, {
      params,
    });
  }

  getRoleDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.roleDetailEndpoint}/${id}`);
  }

  getRoleAbilities(id) {
    return this.axiosIns.get(`${this.jwtConfig.roleAbilitiesEndpoint}/${id}`);
  }

  deleteRoles(data) {
    return this.axiosIns.delete(this.jwtConfig.roleDeleteEndpoint, { data });
  }

  // manager
  createManager(...args) {
    return this.axiosIns.post(this.jwtConfig.managerCreateEndpoint, ...args);
  }

  updateManager(data) {
    return this.axiosIns.put(`${this.jwtConfig.managerUpdateEndpoint}/${data.id}`, data);
  }

  getManagers(params) {
    return this.axiosIns.get(this.jwtConfig.managerListEndpoint, {
      params,
    });
  }

  deleteManagers(data) {
    return this.axiosIns.delete(this.jwtConfig.managerDeleteEndpoint, { data });
  }

  getManagerDetail(params) {
    return this.axiosIns.get(this.jwtConfig.managerDetailEndpoint, {
      params,
    });
  }

  // income expense
  createIncomeExpense(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseCreateEndpoint, ...args);
  }

  updateIncomeExpense(data) {
    return this.axiosIns.put(`${this.jwtConfig.incomeExpenseUpdateEndpoint}/${data.id}`, data);
  }

  calculateAllocation(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseCalculateAllocationEndpoint, ...args);
  }

  getIncomeExpenses(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseListEndpoint, {
      params,
    });
  }

  exportIncomeExpenses(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseExportEndpoint, {
      params,
    });
  }

  getIncomeExpenseHTML(id) {
    return this.axiosIns.get(`${this.jwtConfig.incomeExpenseHTMLEndpoint}/${id}`);
  }

  getIncomeExpenseDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.incomeExpenseDetailEndpoint}/${id}`);
  }

  getIncomeExpenseAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseAnalyticsEndpoint, {
      params,
    });
  }

  deleteIncomeExpenses(data) {
    return this.axiosIns.delete(this.jwtConfig.incomeExpenseDeleteEndpoint, { data });
  }

  importIncomeExpense(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseImportEndpoint, ...args);
  }

  generateImportIncomeExpensesTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseGenerateImportTemplateEndpoint, { params });
  }

  approveIncomeExpenses(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseApproveEndpoint, ...args);
  }

  linkInvoice(data) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseLinkInvoiceEndpoint, data);
  }

  // notification
  createNotification(...args) {
    return this.axiosIns.post(this.jwtConfig.notificationCreateEndpoint, ...args);
  }

  updateNotification(object) {
    const { id, data } = object;
    return this.axiosIns.post(`${this.jwtConfig.notificationUpdateEndpoint}/${id}`, data);
  }

  getNotifications(params) {
    return this.axiosIns.get(this.jwtConfig.notificationListEndpoint, {
      params,
    });
  }

  getSystemNotifications(params) {
    return this.axiosIns.get(this.jwtConfig.systemNotificationListEndpoint, {
      params,
    });
  }

  markSystemNotificationsAsRead() {
    return this.axiosIns.post(this.jwtConfig.systemNotificationMarkAsReadListEndpoint);
  }

  getUnreadSystemNotifications(params) {
    return this.axiosIns.get(this.jwtConfig.systemNotificationCountUnreadListEndpoint, {
      params,
    });
  }

  getNotificationDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.notificationDetailEndpoint}/${id}`);
  }

  deleteNotifications(data) {
    return this.axiosIns.delete(this.jwtConfig.notificationDeleteEndpoint, { data });
  }

  // Permission
  getPermissions() {
    return this.axiosIns.get(this.jwtConfig.permissionListEndPoint);
  }

  getPermissionGroups() {
    return this.axiosIns.get(this.jwtConfig.permissionGroupListEndPoint);
  }

  // user
  getUserProfile() {
    return this.axiosIns.get(this.jwtConfig.userProfileEndpoint);
  }

  updateUserProfile(...args) {
    return this.axiosIns.post(this.jwtConfig.userProfileUpdateEndpoint, ...args);
  }

  verifyEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.userVerifyEmailEndpoint, ...args);
  }

  verifyPhone(...args) {
    return this.axiosIns.post(this.jwtConfig.userVerifyPhoneEndpoint, ...args);
  }

  getACL(...args) {
    return this.axiosIns.get(this.jwtConfig.userACLEndpoint, ...args);
  }

  requestVerifyEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestVerifyEmailEndpoint, ...args);
  }

  requestMissCallOTP(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestMissCallOTPEndpoint, ...args);
  }

  requestVerifyPhoneOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestVerifyPhoneOTPEndpoint, ...args);
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args);
  }

  resetPasswordWithOTP(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, ...args);
  }

  changeUserPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.userChangePasswordEndpoint, ...args);
  }

  // dashboard
  getDashboard() {
    return this.axiosIns.get(this.jwtConfig.dashboardEndpoint);
  }

  // configuration
  getUserConfiguration() {
    return this.axiosIns.get(this.jwtConfig.userConfigurationEndpoint);
  }

  updateUserConfiguration(...args) {
    return this.axiosIns.post(this.jwtConfig.userConfigurationUpdateEndpoint, ...args);
  }

  getEmailConfiguration() {
    return this.axiosIns.get(this.jwtConfig.emailConfigurationEndpoint);
  }

  updateEmailConfiguration(...args) {
    return this.axiosIns.put(this.jwtConfig.emailConfigurationUpdateEndpoint, ...args);
  }

  getApartmentConfiguration(params) {
    return this.axiosIns.get(this.jwtConfig.apartmentConfigurationEndpoint, { params });
  }

  updateApartmentConfiguration(data) {
    return this.axiosIns.put(`${this.jwtConfig.apartmentConfigurationUpdateEndpoint}`, data);
  }

  // prepaid
  createPrepaid(...args) {
    return this.axiosIns.post(this.jwtConfig.prepaidCreateEndpoint, ...args);
  }

  updatePrepaid(data) {
    return this.axiosIns.put(`${this.jwtConfig.prepaidUpdateEndpoint}/${data.id}`, data);
  }

  getPrepaids(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidListEndpoint, {
      params,
    });
  }

  exportPrepaids(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidExportEndpoint, {
      params,
    });
  }

  getPrepaidDetailByContract(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidDetailByContractEndpoint, {
      params,
    });
  }

  getRemainPrepaidByContract(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidRemainByContractEndpoint, {
      params,
    });
  }

  deletePrepaid(data) {
    return this.axiosIns.delete(this.jwtConfig.prepaidDeleteEndpoint, { data });
  }

  // admin
  getHosts(params) {
    return this.axiosIns.get(this.jwtConfig.adminListHost, {
      params,
    });
  }

  getHostAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.adminListHostAnalytics, {
      params,
    });
  }

  getHostDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.adminHostDetail}/${id}`);
  }

  updateHostSubscription(data) {
    return this.axiosIns.post(this.jwtConfig.adminHostSubscription, data);
  }

  syncHostDataFromItro(data) {
    return this.axiosIns.post(this.jwtConfig.adminHostSyncDataFromOldSystem, data);
  }

  changeHostPassword(data) {
    return this.axiosIns.post(this.jwtConfig.adminHostChangePassword, data);
  }

  getAdminOrders(params) {
    return this.axiosIns.get(this.jwtConfig.adminListOrder, {
      params,
    });
  }

  getOrderAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.adminListOrderAnalytics, {
      params,
    });
  }

  getOrderDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.adminOrderDetail}/${id}`);
  }

  getAdminTenants(params) {
    return this.axiosIns.get(this.jwtConfig.adminListTenant, {
      params,
    });
  }

  getAdminTenantAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.adminListTenantAnalytics, {
      params,
    });
  }

  getAdminTenantDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.adminTenantDetail}/${id}`);
  }

  // attachment
  uploadAttachment(data) {
    return this.axiosIns.post(this.jwtConfig.attachmentUploadEndpoint, data);
  }

  deleteAttachment(data) {
    return this.axiosIns.delete(this.jwtConfig.attachmentDeleteEndpoint, { data });
  }

  // Job
  fetchJobs(params) {
    return this.axiosIns.get(this.jwtConfig.jobEndPoint, { params });
  }

  getJobDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.jobEndPoint}/${id}`);
  }

  reRunJob(id) {
    return this.axiosIns.get(`${this.jwtConfig.jobReRunEndPoint}/${id}`);
  }


  // System log
  fetchSystemLogs(params) {
    return this.axiosIns.get(this.jwtConfig.systemLogEndPoint, { params });
  }


  // bank
  getBanks(params) {
    return this.axiosIns.get(this.jwtConfig.bankListEndpoint, {
      params,
    });
  }

  getBanksTingee(params) {
    return this.axiosIns.get(this.jwtConfig.bankListTingeeEndpoint, {
      params,
    });
  }


  // invoice template
  createTemplate(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceTemplateCreateEndpoint, ...args);
  }

  updateTemplate(object) {
    const { data, id } = object;
    return this.axiosIns.put(`${this.jwtConfig.invoiceTemplateUpdateEndpoint}/${id}`, data);
  }

  previewTemplate(id) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceTemplatePreviewEndpoint}/${id}/preview`);
  }

  getTemplateDetail(data) {
    const { bedId } = data;
    return this.axiosIns.get(`${this.jwtConfig.invoiceTemplateDetailEndpoint}/${bedId}`);
  }

  getTemplates(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceTemplateListEndpoint, {
      params,
    });
  }

  getInvoiceParams() {
    return this.axiosIns.get(this.jwtConfig.invoiceParamsEndpoint);
  }

  deleteTemplates(data) {
    return this.axiosIns.delete(this.jwtConfig.invoiceTemplateDeleteEndpoint, { data });
  }

  // tingee

  connectBankAccount(data) {
    return this.axiosIns.post(this.jwtConfig.tingeeConnectBankAccountEndpoint, data);
  }

  verifyBankAccount(data) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyBankAccountEndpoint, data);
  }
  tingeeVerifyOCBInfo(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyOCBInfoEndpoint, ...args);
  }

  tingeeVerifyOCBInfoWithOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyOCBInfoWithOtpEndpoint, ...args);
  }

  tingeeCreateVAAccount(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeCreateVAAccountEndpoint, ...args);
  }

  getTingeeAccounts() {
    return this.axiosIns.get(this.jwtConfig.tingeeAccountEndpoint);
  }

  deleteTingees(data) {
    return this.axiosIns.delete(this.jwtConfig.tingeeDeleteAccountEndpoint, { data });
  }
  applyOneTimeQrcode(tingeeId) {
    return this.axiosIns.post(this.jwtConfig.tingeeApplyOneTimeQrcodeEndpoint + `/${tingeeId}`);
  }



  // General data
  getGeneralDatas(params) {
    return this.axiosIns.get(this.jwtConfig.generalDataListEndpoint, {
      params,
    });
  }

  getGeneralDataDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.generalDataListEndpoint}/${id}`);
  }

  deleteGeneralDatas(data) {
    return this.axiosIns.delete(this.jwtConfig.generalDataDeleteEndpoint, { data });
  }

  createGeneralData(...args) {
    return this.axiosIns.post(this.jwtConfig.generalDataCreateEndpoint, ...args);
  }

  updateGeneralData(postData) {
    const { data, id } = postData
    return this.axiosIns.put(this.jwtConfig.generalDataUpdateEndpoint + `/${id}`, data);
  }

  //Zalo 

  getZaloLinkURL() {
    return this.axiosIns.get(this.jwtConfig.zaloLinkURLEndpoint);
  }

  linkZaloOA(data) {
    return this.axiosIns.post(this.jwtConfig.zaloLinkOAEndpoint, data);
  }

  getZalos(params) {
    return this.axiosIns.get(this.jwtConfig.zaloListEndpoint, {
      params,
    });
  }


  deleteZalos(data) {
    return this.axiosIns.delete(this.jwtConfig.zaloDeleteEndpoint, { data });
  }

}
